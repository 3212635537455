import {
  assertType,
  clearMessages,
  debounce,
  getContextPath,
  getMetaContent,
  resizeElementsToBgImage
} from "../shared/application.js";
import { useFooterBackgroundColor } from "../shared/uiStyle.js";

const PASSWORD_LOGIN_ELEMENTS_SELECTOR = ".password-login";
const SECOND_ROW_CLASS = "second-grid-row";

/** The event handler for when the user name changes. */
function onUserNameChange(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) return;

  const userName = event.target.value;
  if (userName) checkSSO(userName);
  else unsetSSO();
}

type CheckSsoResponse = NoSsoResponse | EnabledSsoResponse;

interface NoSsoResponse {
  userType: "NORMAL";
}

interface EnabledSsoResponse {
  userType: "SSO";
  redirectTo: string;
  appId: string;
}

/**
 * Sends the given username to the backend to have it checked if it uses sso.
 */
async function checkSSO(userName: string) {
  let response;
  try {
    response = await fetch(`${getContextPath()}/landing/checkSso/${userName}`, {
      headers: { Accept: "application/json" }
    });
  } catch (error) {
    unsetSSO();
    throw error;
  }

  if (!response.ok) {
    unsetSSO();
    return;
  }

  const json: CheckSsoResponse = await response.json();
  if (json.userType === "SSO") {
    setSSO(json, userName);
  } else {
    unsetSSO();
  }
}

/**
 * Changes the view for an SSO user.
 * Hides the password field and shows the sso message.
 * The Login Button is moved up to be in line with the username input.
 */
function setSSO(response: EnabledSsoResponse, userName: string) {
  clearMessages();
  ssoMessageDiv.textContent = getMetaContent("ssoUserMessage");
  hidePasswordLoginElements();
  ssoAbortController = new AbortController();
  loginForm.addEventListener(
    "submit",
    (event) => {
      event.preventDefault();
      window.location.assign(
        `${response.redirectTo}?application=${response.appId}&user=${userName}`
      );
    },
    { signal: ssoAbortController.signal }
  );
}

/**
 * Changes the view for a normal (not SSO) user.
 * Shows the password field and hides the sso message.
 * The Login Button is moved down to be in line with the password input.
 */
function unsetSSO() {
  ssoMessageDiv.textContent = "";
  showPasswordLoginElements();
  if (ssoAbortController) ssoAbortController.abort();
}

/** Hide the elements used for a password login. */
function hidePasswordLoginElements() {
  document
    .querySelectorAll(PASSWORD_LOGIN_ELEMENTS_SELECTOR)
    .forEach((element) => {
      if (!(element instanceof HTMLElement)) return;

      if (element instanceof HTMLInputElement) element.value = "";

      element.hidden = true;
    });
  loginButton.classList.remove(SECOND_ROW_CLASS);
}

/** Show the elements used for a password login. */
function showPasswordLoginElements() {
  document
    .querySelectorAll(PASSWORD_LOGIN_ELEMENTS_SELECTOR)
    .forEach((element) => {
      if (!(element instanceof HTMLElement)) return;

      element.hidden = false;
    });
  loginButton.classList.add(SECOND_ROW_CLASS);
}

/**
 * Resize the application container according to its set background image. This
 * flexibly resizes depending on whether to use a footer backgtound color.
 */
function fitContainerToBackgroundImage() {
  if (useFooterBackgroundColor()) {
    resizeElementsToBgImage(".body-container.landing", (element) => {
      assertType(
        document.querySelector(".app-container.landing"),
        HTMLElement
      ).setInnerWidth(element.scrollWidth);
    });
  } else {
    resizeElementsToBgImage(".app-container.landing");
  }
}

let ssoAbortController: AbortController | null = null;

const loginForm = assertType(
  document.querySelector(".login-form"),
  HTMLFormElement
);
const userNameInput = assertType(
  loginForm.querySelector('input[name="userName"]'),
  HTMLInputElement
);
const loginButton = assertType(
  document.getElementById("submit-button"),
  HTMLButtonElement
);
const ssoMessageDiv = assertType(
  document.querySelector(".sso-message"),
  HTMLDivElement
);

document.querySelector(".footer-container")?.classList.add("landing");
fitContainerToBackgroundImage();

const userName = userNameInput.value;
if (userName) checkSSO(userName);

userNameInput.addEventListener("input", unsetSSO);
userNameInput.addEventListener("input", debounce(1000, onUserNameChange));
