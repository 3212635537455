import { getMetaContent } from "./application.js";

/**
 * Determine whether the UIStyle uses a footer background color.
 *
 * @returns whether to use a footer background color
 */
export function useFooterBackgroundColor(): boolean {
  return getMetaContent("useFooterBackgroundColor") === "true";
}
